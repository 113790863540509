<template>
    <CInput
    type="text"
    class="input-number"
    v-model="inputValue"
    @input="setValue"
    :disabled="disabled"
    />
</template>

<script>
export default {
  name: "FilterOrderNumber",
  data() {
    return {
      inputValue: null,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    resetInput: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    setValue() {
      this.$store.dispatch("saveFilterOrderNumberValue", this.inputValue);
    },
  },
  watch: {
    resetInput: {
      handler(newValue) {
        if (newValue) {
          this.setValue();
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.input-number input {
  text-align: right;
}
</style>
