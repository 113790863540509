<template>
  <div>
      <CTabs 
        variant="tabs" 
        :active-tab="0"
        :fill="true"
      >
        <CTab title="Exportar">
          <CForm @submit="exportData">
            <CCard class="tab-card">
              <CCardBody>
              <CRow>
                <CCol xl="5">
                  <label class="filter-label">Tipo de datos</label>
                  <FilterReport @filter-type-changed="showExportFilters" />
                </CCol>
              </CRow>
              <CRow v-show="showBools.FiltersArea">
                <CCol sm="12" v-show="showBools.FiltersAreaCombo">
                  <label class="filter-label">{{ messages.filters.area }}</label>
                  <FiltersAreaCombo :activeElements="activeItems" :allCountries="true" />
                  <span
                    v-if="areaError"
                    class="error-message error-message--bottom"
                    >{{ messages.validation.filterAreaCombo }}</span
                  >
                </CCol>
              </CRow>
              <CRow>
                <CCol xl="3" v-show="showBools.FilterFY">
                  <label class="filter-label">FY</label>
                  <FilterFY />
                </CCol>
                <CCol xl="6" v-show="showBools.FilterMonthsRange">
                  <label class="filter-label">{{
                    messages.filters.period
                  }}</label>
                  <FilterMonthsRange />
                </CCol>
                <CCol v-show="showBools.FilterFinalizedRef">
                  <span class="pr-3">Mostrar referencias finalizadas</span>
                  <CSwitch
                    color="success"
                    shape="pill"
                    size="sm"
                    class="pt-2"
                    @update:checked="setShowFinalizedValue"
                  />
                </CCol>
                <CCol  v-show="showBools.FilterOrderNumber" sm="6" xl="4">
                  <label class="filter-label d-flex pb-1">
                    <CSwitch 
                      :checked="isOrderNumberChecked"
                      @update:checked="toggleFilterOrderNumber"
                      class="mr-1"
                      color="success"
                      shape="pill"
                      size="sm"
                    /> 
                    <span class="ml-1">Número de pedido</span>
                  </label>
                  <FilterOrderNumber 
                    :disabled="filterOrderNumberDisabled"
                    :resetInput="resetOrderNumber"
                    :key="resetOrderNumberKey"
                  />
                  <span
                    v-if="orderNumberError"
                    class="error-message error-message--bottom"
                    >{{ messages.validation.required }}</span
                  >
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter v-show="showBools.exportSubmitButton">
              <CButton type="submit" color="primary">Descargar</CButton>
            </CCardFooter>
            </CCard>
          </CForm>
        </CTab>
        <CTab title="Importar">
          <CForm @submit="importData">
            <CCard class="tab-card">
              <CCardBody>
                <CRow>
                  <CCol md="5">
                    <label class="filter-label">Tipo de datos</label>
                    <FilterReport @filter-type-changed="updateImportFields" />
                  </CCol>
                </CRow>
                <CRow v-show="showBools.fileInput">
                  <CCol>
                    <label class="filter-label">Seleccionar archivo</label>
                    <div class="file-box w-100 bg-gray-100 border" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                      <input 
                        type="file" 
                        id="assetsFieldHandle" 
                        class="w-0 h-0 d-block opacity-0 overflow-hidden absolute" 
                        @change="updateSelectedFile" 
                        ref="file" 
                        accept="application/vnd.ms-excel, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                      <label 
                        v-show="!selectedFile.length && !showBools.okMessage && !showBools.koMessage" 
                        for="assetsFieldHandle" 
                        class="block cursor-pointer"
                      >
                        <div>
                          Arrastrar o <u>seleccionar</u> el archivo<br>
                          <CIcon size="xl" class="mt-4" name="cil-arrow-thick-from-bottom" />
                        </div>
                      </label>
                      <div v-show="selectedFile.length" v-cloak>
                        <span class="text-sm p-1" v-for="file in selectedFile">
                          {{ file.name }}
                          <CButton 
                            class="ml-2" 
                            type="button" 
                            @click="removeSelectedFile(selectedFile.indexOf(file))" 
                            title="Eliminar Archivo"
                            color="secondary"
                          >
                            Eliminar
                          </CButton>
                        </span>
                      </div>
                      <div v-show="showBools.okMessage" class="ok-message">
                        <CIcon size="xl" name="cil-check-circle" /> Datos cargados<br>
                        <CButton class="mt-3" color="success" @click="updateImportFields(importSelectedReport)">Aceptar</CButton>
                      </div>
                      <div v-show="showBools.koMessage" class="ok-message">
                        <CIcon size="xl" name="cil-x-circle" /> 
                        ERROR {{ errorCode }}<br> 
                        {{ errorMessage }}
                        <br>
                        <CButton class="mt-3" color="danger" @click="updateImportFields(importSelectedReport)">Aceptar</CButton>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter v-show="selectedFile.length">
                <CButton type="submit" color="primary">Cargar datos</CButton>
              </CCardFooter>
            </CCard>
          </CForm>
        </CTab>
      </CTabs>
      <div class="text-center">
        <Loader
          v-show="showBools.Loader"
          width="100px"
          height="100px"
          fill-color="#c2c2c2"
          cssClass="fadeIn anim-speed--1s"
        />
      </div>
  </div>
</template>

<script>
import LogoExcel from "@/components/atoms/LogoExcel";
const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterMonth = () =>
  import(
    /* webpackChunkName: "filterMonth" */ "@/components/molecules/FilterMonth"
  );
const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filterMonthsRange" */ "@/components/molecules/FilterMonthsRange"
  );

import Api from "@/services/Api";
import Loader from "@/components/atoms/Loader";
import FilterReport from "../../components/molecules/FilterReport.vue";
import { CInputCheckbox, CSwitch } from "@coreui/vue";
import {saveAs} from "file-saver";
import FilterOrderNumber from "../../components/molecules/FilterOrderNumber.vue";

export default {
  name: "LoadManager",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonth,
    FilterMonthsRange,
    Loader,
    LogoExcel,
    FilterReport,
    CInputCheckbox,
    CSwitch,
    FilterOrderNumber
},
  data() {
    return {
      filtersAreaList: [ "countries", "regionals", "aspms", "concessions"],
      activeItems: [],
      areaError: false,
      user: null,
      lang: null,
      messages: null,
      showBools: {
        FiltersDataType: false,
        FiltersAreaCombo: false,
        FilterFY: false,
        FilterMonth: false,
        FilterMonthsRange: false,
        FiltersArea: false,
        exportSubmitButton: false,
        FilterMonthsRange: false,
        FilterFinalizedRef: false,
        importSubmitButton: false,
        fileInput: false,
        okMessage: false,
        koMessage: false,
        FilterOrderNumber: false,
        Loader: false,
      },
      selectedArea: null,
      validateAreaParams: false,
      exportSelectedReport: null,
      importSelectedReport: null,
      selectedFile: [],
      exportParamsList: [],
      paramShowFinalized: 0,
      errorCode: null,
      errorMessage: '',
      filterOrderNumberDisabled: true,
      resetOrderNumberKey: 0,
      resetOrderNumber: false, 
      isOrderNumberChecked: false,
      orderNumberError: false,
      isExportDataValidated: false,
    };
  },
  computed: {
  },
  methods: { 
    mountFiltersAreaCombo(filtersNames) {
      this.showBools.FiltersAreaCombo = true;
      this.activeItems = filtersNames;
    },
    setShowFinalizedValue(value) {
      this.paramShowFinalized = value ? 1 : 0;
    },
    showExportFilters(selectedValue) {
      this.exportSelectedReport = selectedValue;
      this.resetFilters();

      switch (selectedValue) {
        case "obj_bono_volumen":
        case "obj_accesorios":
        case "parque":
          this.showBools.FiltersArea = true;
          this.showBools.FilterFY = true;
          this.showBools.FilterMonthsRange = true;
          this.activeItems = [ "countries", "regionals", "aspms", "concessions"];
          this.validateAreaParams = true;
          this.exportParamsList = [
            "regionalCode",
            "aspm",
            "concessionCode",
            "countryCode",
            "fiscalYear",
            "sinceMonth",
            "upToMonth",
          ];
          this.showBools.FilterOrderNumber = false;
          break;
        case "obj_parte_diario":
        case "compras_parte_diario":
          this.showBools.FiltersArea = true;
          this.showBools.FilterFY = true;
          this.showBools.FilterMonthsRange = true;
          this.showBools.FilterFinalizedRef = false;
          this.activeItems = [ "countries"];
          this.validateAreaParams = true;
          this.exportParamsList = [
            "regionalCode",
            "aspm",
            "concessionCode",
            "countryCode",
            "fiscalYear",
            "sinceMonth",
            "upToMonth",
          ];
          this.showBools.FilterOrderNumber = false;
          break;
        case "holidays":
          this.showBools.FiltersArea = true;
          this.showBools.FilterFY = true;
          this.showBools.FilterMonthsRange = false;
          this.showBools.FilterFinalizedRef = false;
          this.activeItems = [ "countries"];
          this.validateAreaParams = true;
          this.exportParamsList = [
            "countryCode",
            "fiscalYear",
          ];
          this.showBools.FilterOrderNumber = false;
          break;
        case "oil_program":
          this.showBools.FiltersArea = true;
          this.showBools.FilterFY = true;
          this.showBools.FilterMonthsRange = false;
          this.showBools.FilterFinalizedRef = false;
          this.activeItems = [ "countries", "regionals", "aspms", "concessions"];
          this.validateAreaParams = true;
          this.exportParamsList = [
            "regionalCode",
            "aspm",
            "concessionCode",
            "countryCode",
            "fiscalYear",
          ];
          this.showBools.FilterOrderNumber = false;
          break;
        case "recall_campaign":
          this.showBools.FiltersArea = false;
          this.showBools.FilterFY = false;
          this.showBools.FilterMonthsRange = false;
          this.showBools.FilterFinalizedRef = true;
          this.activeItems = [];
          this.validateAreaParams = false;
          this.exportParamsList = [
            "showFinalized",
          ];
          this.showBools.FilterOrderNumber = false;
          break;
        case "descuentos_pedidos":
          this.showBools.FiltersArea = false;
          this.showBools.FilterFY = true;
          this.showBools.FilterMonthsRange = false;
          this.activeItems = [];
          this.validateAreaParams = false;
          this.exportParamsList = [
            "fiscalYear",
          ];
          this.showBools.FilterOrderNumber = true;
          break;
        default:
          this.showBools.FiltersArea = false;
          this.showBools.FilterFY = false;
          this.showBools.FilterMonthsRange = false;
          this.showBools.FilterFinalizedRef = false;
          this.activeItems = [];
          this.validateAreaParams = false;
          this.exportParamsList = [];
          this.showBools.FilterOrderNumber = false;
          break;
      }
      this.showBools.exportSubmitButton = selectedValue !== null;
    },
    updateImportFields(selectedValue) {
      this.importSelectedReport = selectedValue;
      this.removeSelectedFile(0);
      this.showBools.importSubmitButton = selectedValue !== null;
      this.showBools.fileInput = selectedValue !== null;
      this.showBools.okMessage = false;
      this.showBools.koMessage = false;
    },
    exportData(event) {
      event.preventDefault();
      const button = event.target.querySelector('button');
      this.validateExportData();
      if (this.isExportDataValidated) {
        this.showBools.Loader = true;
        button.setAttribute("disabled", "disabled");
        const params = this.getParams();
        Api.sendPost(`/admin/reportAgent/downloadExcel?reportName=${this.exportSelectedReport}`, params, { responseType: "blob" }).then(
          (response) => {
            if (response.status == 200) {
              const contentDisposition = response.headers["content-disposition"];
              const fileName = contentDisposition.match(/filename=(.*?);/)[1];
              const contentType = contentDisposition.match(/content-type=(.*$)/)[1];
              const data = response.data;
              if (data && fileName && contentType) {
                const blob = new Blob([data], {type: contentType,});
                saveAs(blob, fileName);  
              }   
            }
            this.showBools.Loader = false;
            button.removeAttribute("disabled");
          }
        );
      }
    },
    updateSelectedFile() {
      this.selectedFile = [...this.$refs.file.files];
    },
    removeSelectedFile(i) {
      this.selectedFile.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.updateSelectedFile();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green');
    },
    importData(event) {
      event.preventDefault();
      const file = this.selectedFile[0];
      if(file) {
        const button = event.target.querySelector('button');
        button.setAttribute("disabled", "disabled");
        this.showBools.Loader = true;
        const formData = new FormData();
        formData.append('file', file);
        Api.sendPost(`/admin/reportAgent/upload?reportName=${this.importSelectedReport}`, formData).then(
          (response) => {
            button.removeAttribute("disabled");
            this.showBools.Loader = false;
            if (response.status === 200 ) {
              if (response.data.code === 200) {
                this.showOkMessage();
              } else {
                this.showKoMessage(response.data.code, response.data.message );
              }
            }
          }
        );
      }
    },
    showOkMessage() {
      this.showBools.okMessage = true;
      this.removeSelectedFile(0);
    },
    showKoMessage(error, message) {
      this.errorCode = error;
      this.errorMessage = error === 906 ? message : 'No se han cargado los datos';
      this.removeSelectedFile(0);
      this.showBools.koMessage = true;
    },
    getParams() {
      const params = {
        ...this.$store.getters.getFiltersAreaComboParams,
          fiscalYear: this.$store.getters.getFilterFYValue,
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
          showFinalized: this.paramShowFinalized,
          orderID: this.$store.getters.getFilterOrderNumberValue,
      }
      const filteredParams = {};
        for (const key of this.exportParamsList) {
          if (params.hasOwnProperty(key)) {
            filteredParams[key] = params[key];
          }
        }
      return filteredParams;
    },
    resetFilters() {
      this.$store.state.filterAreaValue = null;
      this.$store.state.filterAreaCategory = "null";
      this.$store.state.filterAreaCategoriesResult = null;
      this.$store.state.filtersAreaComboParams = null;
      this.isOrderNumberChecked && this.disableFilterOrderNumber();
      this.areaError = false;
      this.orderNumberError = false;
    },
    toggleFilterOrderNumber(value) {
      this.filterOrderNumberDisabled = !value;
      if (this.filterOrderNumberDisabled) {
        this.disableFilterOrderNumber();
        this.showBools.FilterFY = true;
        this.exportParamsList = [
            "fiscalYear",
          ];
      } else {
        this.showBools.FilterFY = false;
        this.isOrderNumberChecked = true;
        this.exportParamsList = ["orderID"];
      }
    },
    emptyFilterOrderNumber() {
      this.resetOrderNumberKey += 1;
      this.resetOrderNumber = true;
    },
    disableFilterOrderNumber() {
      this.isOrderNumberChecked = false;
      this.filterOrderNumberDisabled = true;
      this.emptyFilterOrderNumber();
    },
    validateExportData() {
      if (this.isOrderNumberChecked) {
        if (this.$store.getters.getFilterOrderNumberValue) {
          this.orderNumberError = false;
          this.isExportDataValidated = true;
        } else {
          this.orderNumberError = true;
          this.isExportDataValidated = false;
        }
      } else if (this.validateAreaParams && !this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
        this.isExportDataValidated = false;
      } else {
        this.areaError = false;
        this.isExportDataValidated = true;
      }
    },
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.mountFiltersAreaCombo(this.filtersAreaList);
  },
};
</script>

<style lang="scss" scoped>

  .tabs {
    a {
      background-color: #ffffff;
    }
  } 
  .tab-card {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
    border-color: #c4c9d0;
  }
  .w-0 {
    width: 0px;
  }
  .h-0 {
    height: 0px;
    line-height: 0px;
  }
  .file-box {
    text-align: center;
    padding: 2rem;
    border-radius: 4px;
  }
  .bg-green {
    background: #7dc74e;
  }
  .ok-message {
    text-align: center;
    font-size: 1rem;
  }
</style>


