<template>
  <CSelect 
  :options="options" 
  :placeholder="placeholder"
  :value="selectedOption" 
  v-model="selectedOption"
  @change="setValue"

   />
</template>

<script>
import Api from "@/services/Api";

export default {
  name: "FilterReport",
  data() {
    return {
      options: null,
      lang: null,
      messages: null,
      selectedOption: null,
    };
  },
  computed: {
    placeholder() {
      return this.$store.getters.getLangMessages.filters.reportsPlaceholder;
    },
  },
  methods: {
    setValue() {
      this.$store.dispatch("saveFilterReportValue", {
        value: this.selectedOption,
      });

      // Emit an event when the value changes
      this.$emit("filter-type-changed", this.selectedOption);
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    const queryUrl = "/sys/agentReport/list";
    Api.sendGet(queryUrl).then((response) => {
      if(response.status === 200) {
          if (response.data.code === 200) {
            const arrayResult = [];
            response.data.data.forEach((element) => {
            if(this.messages.filters.report[element]) {
              let item = {
                value: element,
                label: this.messages.filters.report[element],
              };
              arrayResult.push(item);
            }
          });
          this.options = arrayResult;
          this.setValue();
          } else {
            console.error('[API ERROR] agentReport data.code', response.data.code)
          }
        } else {
          console.error('[API ERROR] agentReport', response.status)
        }
    });
  },
};
</script>
